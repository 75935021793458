import GeneralHeader from "../Header/GeneralHeader";
import GeneralFooter from "../Footer/GeneralFooter";
import Icons from '../Shared/IconsImport';

export default function DefaultLayout(props) {
	return (
		<>
			<Icons />
			<div className="layout">
				<GeneralHeader nav={props.nav} logo={props.logo} />
				{props.children}
				{props.footer === false ? null : (
					<GeneralFooter footer={props.footer} />
				)}
			</div>
		</>
	);
}