import { Icon, OptionalTypeIcon } from "./types";

// remove fa- from string
export const getIconWithoutTypeAndPrefix = <T extends string>(iconClass: T): T => {
	if (iconClass == null) return;

	let splitIcon = iconClass.split(" ");

	if (splitIcon.length > 1) {
		{/* <i class="fa-brands fa-font-awesome"></i> */ }
		if (splitIcon[1].includes("fa-")) {
			splitIcon[1] = splitIcon[1].replace("fa-", "");
		}
	}

	const final = splitIcon.length > 1 ? splitIcon[1] : iconClass;

	return final as T;
}

export const formatIcon = <T extends string>(icon: T): T => {
	// This should not be necessary, but some endpoints bass through invalid icon identifiers with the prefix. Need to fix on the backend.
	let iconFormatted: T = getIconWithoutTypeAndPrefix<T>(icon);

	return iconFormatted;
}

export const convertIconToDefinition = <T extends string, D>(icon: OptionalTypeIcon<T>, getIconDefinition: (icon: T) => D | null) => {
	let { icon: iconClass } = icon;

	iconClass = formatIcon<T>(iconClass);

	const iconDefinition = getIconDefinition(iconClass);

	if (!iconDefinition) {
		console.error("could not find icon: ", iconClass, icon);
	}

	return iconDefinition;
}
