import { fal } from "@fortawesome/pro-light-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";

import "@fortawesome/fontawesome-svg-core/styles.css";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(fal, far);

// Loads icons in layout instead of in main application layout to reduce app bundle size and have it downloaded per page.
// This means that if a layout does not include the icons component, it will not have font awesome support.
const Icons = () => {
	return <></>;
}

export default Icons;