import React, { Component } from "react";
import Link from "next/link";
import dynamic from "next/dynamic";
import { SiteContext } from "../Contexts/SiteContext";
import { Fragment } from "react";
import { RegularIcon } from "../Shared/Icons/regular";

const SearchModal = dynamic(import("./SearchModal"));

export default class GeneralHeader extends Component {
	constructor(props) {
		super(props);

		this.state = {
			error: null,
			isLoaded: false,
			main_navbar: [],
			sub_navbar: [],
			setOpen: false,
			searchOpen: false
		};

		this.openSearch = this.openSearch.bind(this);
		this.closeSearch = this.closeSearch.bind(this);
	}

	openSearch() {
		this.setState({
			searchOpen: true
		});
	}
	closeSearch() {
		this.setState({
			searchOpen: false
		});
	}

	render() {
		return (
			<SiteContext.Consumer>
				{({ toggleSideMenu }) => (
					<Fragment>
						<div className="navbar-fixed generalNav">
							<nav className="nav-extended">
								<div className="nav-wrapper">
									<ul className="right hide-on-med-and-down navBlock topNavList">
										{this.props.nav[0] && this.props.nav[0].children && this.props.nav[0].children.map(nav => {
											return nav.type == "Entry" ? (
												<li key={nav.id}
													className="top_navbar_list right navBlockItem">
													<Link href={`/${nav.uri}`}>
														{nav.title}
													</Link>
												</li>
											) : (
												<li
													key={nav.id}
													className="top_navbar_list right navBlockItem"
												>
													<a
														href={nav.url}
														title={nav.title}
														target={
															nav.newWindow
																? "_blank"
																: "_self"
														}
													>
														{nav.title}
													</a>
												</li>
											)
										})
										}
									</ul>
								</div>
								<div className={`nav-content ${this.state.setOpen ? "dark" : ""}`}>
									<a
										href="#"
										data-target="mobile-demoo"
										className="sidenav-trigger right"
										onClick={() => {
											this.state.setOpen ? this.setState({ setOpen: false }) : this.setState({ setOpen: true });
											this.state.setOpen ? toggleSideMenu(false) : toggleSideMenu(true);
										}
										}
										title="Menu"
									>
										<RegularIcon icon={{ icon: this.state.setOpen ? "times" : "bars", size: "lg" }} />
									</a>
									<div className="left navBlockItem valign-wrapper logo">
										<Link className="brand-logo" title="Honda South Africa" href={`/`}>
											<img src={this.props.logo.general} alt="Honda South Africa" title="Honda South Africa" />
										</Link>
									</div>
									<ul className="right hide-on-med-and-down navBlock bottomNavList">
										<li
											className="right navBlockItem"
											onClick={this.openSearch}
										>
											<RegularIcon icon={{ icon: "search" }} />
										</li>
										{this.props.nav.length > 1 && this.props.nav[1].children && this.props.nav[1].children.map(nav => {
											return nav.type == "Entry" ? (
												<li key={nav.id} className="top_navbar_list right navBlockItem">
													<Link href={`/${nav.uri}`}>
														{nav.title}
													</Link>
												</li>
											) : (
												<li
													key={nav.id}
													className="top_navbar_list right navBlockItem"
												>
													<a
														href={nav.url}
														title={nav.title}
														target={
															nav.newWindow
																? "_blank"
																: "_self"
														}
													>
														{nav.title}
													</a>
												</li>
											)
										})
										}
									</ul>
								</div>
							</nav>
							<div
								className="openSideNavMobile"
								style={{
									visibility: `${this.state.setOpen ? "visible" : "hidden"
										}`
								}}
								onClick={() => this.setState({ setOpen: false })}
							>
								<div
									// className="sidenav"
									className={
										this.state.setOpen
											? "openSideNav sidenav"
											: "closeSideNav sidenav"
									}
									onClick={e => e.stopPropagation()}
								>
									<ul className="navBlock topNavList">
										<li
											className="navBlockItem"
											onClick={this.openSearch}
										>
											<a><RegularIcon icon={{ icon: "search" }} /></a>
										</li>
										{this.props.nav.length > 0 && this.props.nav[0].children && this.props.nav[0].children.map(nav => {
											return nav.type == "Entry" ?
												(
													<li key={nav.id} className="top_navbar_list navBlockItem">
														<Link href={`/${nav.uri}`}>
															<a>{nav.title}</a>
														</Link>
													</li>
												) : (
													<li key={nav.id} className="navBlockItem">
														<a href={nav.url}>{nav.title}</a>
													</li>
												)
										})}
									</ul>
									<ul className="navBlock bottomNavList">
										{this.props.nav.length > 1 && this.props.nav[1].children && this.props.nav[1].children.map(nav => {
											return nav.type == "Entry" && (<li
												key={nav.id}
												title={nav.title}
												className="top_navbar_list navBlockItem">
												<Link href={`/${nav.uri}`}>
													<a>{nav.title}</a>
												</Link>
											</li>)
										})}
									</ul>
								</div>
							</div>
						</div>
						<SearchModal
							searchOpen={this.state.searchOpen}
							close={this.closeSearch}
						/>
					</Fragment>
				)}
			</SiteContext.Consumer>
		);
	}
}